module.exports = {
	"email": "E-post",
	"phone": "Telefon",
	"name": "Namn",
	"avatar": "Profilbild",
	"save": "Spara",
	"saved": "Spara",
	"saving": "Sparar",
	"edit": "Redigera",
	"create": "Skapa",
	"remove": "Radera",
	"reinvite": "Bjud in på nytt",
	"createUser": "Skapa användare",
	"loading": "Laddar {resource}",
	"dashboard": "Dashboard",
	"project": "Projekt | Projekt",
	"theProject": "Projektet",
	"map": "Karta",
	"yourProject": "Ditt projekt | Dina projekt",
	"allProjects": "Alla projekt",
	"noProjects": "Ingen projekt",
	"residential": "Bostad| Bostäder",
	"noResidentials": "Inga bostäder",
	"residentialTemplate": "Bostadstyp | Bostadstyper",
	"createResidentialTemplate": "Skapa bostadstyp",
	"createUnitTemplate": "Skapa enhetstyp",
	"user": "Användare | Användare",
	"settings": "Inställningar",
	"login": "Logga in",
	"logout": "Logga ut",
	"termsCondition": "Avtalsvillkor",
	"privacyPolicy": "Persondataskydd",
	"cookiePolicy": "Cookiepolicy",
	"menu": "Meny",
	"followUs": "Följ oss",
	"getStarted": "Kom i gång",
	"noAccess": "Ingen åtkomst",
	"projectName": "Projektnamn",
	"publish": "Publicera",
	"publishing": "Publicering",
	"published": "Publicerat",
	"unpublished": "Inte publicerat",
	"domain": "Domän",
	"retry": "Försök igen",
	"close": "Stäng",
	"showDetails": "Visa detaljer",
	"hideDetails": "Dölj detaljer",
	"createLead": "Ny intressent",
	"newLead": "Ny intressent",
	"lead": "Intressent | Intressenter",
	"leadMessage": "Besked/Information",
	"message": "Meddelande | Meddelanden",
	"newMessage": "Nytt meddelande",
	"comment": "@:message",
	"noMessages": "Inga meddelande",
	"description": "Beskrivning",
	"showMore": "Visa fler",
	"noLeads": "Inga intressenter",
	"openhouse": "Visning | Visningar",
	"openhouseOnRequest": "Visning enligt avtal",
	"noOpenhouse": "Inga visningar",
	"createOpenhouse": "Ny visning",
	"from": "Från",
	"to": "Till",
	"date": "Datum",
	"role": "Roll | Roller",
	"flatfinder": "Bostadsväljare | Bostadsväljare",
	"unitSelector": "Enhetsväljare | Enhetsväljare",
	"flatfinderTypeFlat": "Lägenhetsväljare | Lägenhetsväljare",
	"flatfinderTypePlot": "Tomtväljare | Tomtväljare",
	"flatfinderTypeOffice": "Kontor | Kontor",
	"createFlatfinder": "Ny bostadsväljare",
	"createUnitSelector": "Ny enhetsväljare",
	"home": "Hem",
	"statistics": "Statistik",
	"notset": "Inte fastställt",
	"projectmanager": "Projektmedarbetare | Projektmedarbetare",
	"customermanager": "Kundtjänstmedarbetare | Kundtjänstmedarbetare",
	"complaintmanager": "Reklamationsansvarig | Reklamationsansvariga",
	"search": "Sök",
	"searchFor": "Sök efter",
	"cancel": "Avbryt",
	"confirm": "Bekräfta",
	"couldnotfindonquery": "Ingen träff för ”{query}”",
	"selectx": "Välj {x}",
	"brochure": "Prospekt",
	"downloadBrochure": "Ladda ned prospekt",
	"logo": "Logo",
	"logoInverted": "Logo (ljus variant)",
	"favicon": "Favicon (Webbläsarikon)",
	"gallery": "Galleri",
	"internalId": "Intern-ID",
	"createProject": "Nytt projekt",
	"address": "Adress",
	"postcode": "Postnummer",
	"city": "Ort",
	"county": "Kommun",
	"latitude": "Latitud",
	"longitude": "Longitud",
	"position": "Position",
	"createResidential": "Ny bostad",
	"cadastre": "Fastighetsregister/Matrikel",
	"residentialnumber": "Fastighetsbeteckning",
	"number": "Nummer",
	"street": "Gatuadress",
	"floorplan": "Planskiss | Planskisser",
	"dragImageOrDrop": "Dra bilden hit eller <b>klicka</b> för att ladda upp | Dra bilder hit eller <b>klicka</b> för att ladda upp",
	"dragFilesOrDrop": "Dra filer {extension} hit eller <b>klicka</b> för att ladda upp",
	"dragFileOrDrop": "Dra fil {extension} hit eller <b>klicka</b> för att ladda upp",
	"price": "Pris",
	"residentialProperties": "Nyckelinfo",
	"propertyType": "Bostadstyp",
	"propertyTypeWarning": "<b>OBS!</b> Du har valt bostadstyp ”{value}” och måste därför lägga in värden per enhet/objekt.",
	"numberOfBedrooms": "Sovrum",
	"numberOfRooms": "Rum",
	"numberOfBathrooms": "Bad/WC",
	"primaryRoomArea": "Boyta/Boarea",
	"grossArea": "Bruttoarea",
	"useableArea": "Bruksarea",
	"floor": "Våning",
	"numberOfFloors": "Antal våningar",
	"plotArea": "Tomtareal",
	"ownershipType": "Ägarform",
	"numberOfParkingSpots": "Antal parkeringsplatser",
	"energyLabel": "Energimärkning",
	"energyLabelColor": "",
	"plotOwned": "Egen tomt",
	"plotGroundTax": "Fastighetsskatt",
	"plotOwnershipType": "Ägarförhållande (tomt)",
	"communityTax": "Kommunala avgifter",
	"taxValue": "Fastighetsvärde",
	"constructionYear": "Byggår",
	"renovatedYear": "Renoverat år",
	"value": "Värde",
	"add": "Lägg till",
	"custom": "Egendefinierat",
	"yes": "Ja",
	"no": "Nej",
	"quantity": "Antal",
	"none": "Ingen/Inget",
	"bid": "Bud",
	"addBid": "Nytt bud",
	"bidUrl": "Länk för budgivning",
	"purchaseUrl": "Länk för signering av köpekontrakt",
	"size": "Storlek",
	"sale": "Försäljning",
	"basis": "Underlag",
	"presentation": "Presentation",
	"integration": "Integration | Integrationer",
	"next": "Nästa",
	"prev": "Föregående",
	"cover": "Cover",
	"propertyType:detached": "Enfamiljshus | Enfamiljshus",
	"propertyType:flat": "Lägenhet | Lägenheter",
	"propertyType:terraced": "Radhus",
	"propertyType:semidetached": "Parhus",
	"propertyType:plot": "Bostadstomt | Bostadstomter",
	"propertyType:leisure": "Stuga | Stugor",
	"propertyType:leisurePlot": "Fritidstomt | Fritidstomter",
	"propertyType:office": "Kontor | Kontor",
	"openhouseReferences": "Var är det visning?",
	"isInterestedIn": "Är intresserad av",
	"leadReferences": "@:isInterestedIn",
	"theWholeProject": "Hela projektet",
	"getInTouch": "Kontakta oss",
	"leadFormTitle": "Intresseanmälan",
	"leadConfirm": "Tack för ditt intresse",
	"residentialStatus": {
		"draft": "@:unpublished",
		"upcoming": "Kommande försäljning",
		"sale": "Till salu",
		"sold": "Sålt",
		"reserved": "Reserverat"
	},
	"residentialStatus:sale": {
		"draft": "@:unpublished",
		"upcoming": "Kommande försäljning",
		"sale": "Till salu",
		"sold": "Sålt",
		"reserved": "Reserverat",
		"development": "Under utveckling"
	},
	"residentialStatus:rent": {
		"draft": "@:unpublished",
		"upcoming": "Kommande försäljning",
		"reserved": "Reserverat",
		"rent": "Till uthyrning",
		"rented": "Uthyrd",
		"development": "Under utveckling"
	},
	"viewResidentials": "Se bostäder",
	"status": "Status",
	"bidAction": "Köp",
	"residentialSaleFee": "Omkostnader",
	"residentialSaleFixed": "Fastpris",
	"residentialSaleFeeIncluded": "Angivet pris är inklusive omkostnader",
	"contact": "Kontakt | Kontakter",
	"editContact": "Ändra kontakt",
	"createContact": "Skapa kontakt",
	"contactUpsell": "Jag vill ha information om liknande projekt",
	"leadPrivacy": "Jag vill bli kontaktad i enlighet med {0}",
	"contractPrivacy": "Jag är införstådd med {0}",
	"salesmanVisibility": "Kundtjänstmedarbetarnas kontaktinformation för detta projekt kommer att visas på projektsidan",
	"areYouSure": "Är du säker?",
	"somethingWentWrong": "Ett fel inträffade",
	"completed": "Avslutat/Genomfört",
	"complete": "Avsluta/Genomför",
	"completing": "Avslutar/Genomför",
	"youAreAboutToRemove": "Du håller på att radera",
	"removeRelationsTitle": "Du raderar även tillhörande:",
	"showAll": "Visa alla",
	"attachment": "Bilaga | Bilagor",
	"preview": "Förhandsvisa",
	"transferToUser": "Flytta anslutna datum till:",
	"upcomingDeadlines": "Kommande tidsfrister",
	"noDeadlines": "Inga kommande tidsfrister",
	"recentActivity": "Senaste händelser",
	"noActivity": "Inga händelser",
	"task": "Uppdrag | Uppdrag",
	"noTasks": "Inga uppdrag",
	"new": "Ny",
	"showCoordinates": "Visa koordinater",
	"readOurTerms": "Läs igenom avtalsvillkoren",
	"iAcceptTerms": "Jag har läst igenom och godkänner avtalsvillkoren",
	"sendAndAccept": "Fortsätt",
	"privacy": "Dataskyddsförordningen",
	"notfoundTitle": "Sidan finns inte",
	"projectWillPublish": "Publiceras snart",
	"projectWillPublishAt": "Publiceras den {date}",
	"projectUnpublished": "Projektet är avslutat",
	"pageMadeBy": "Sidan är skapad i",
	"processedBy": "Behandlat av",
	"markAsProcessed": "Ange som behandlat",
	"openhouseAtValue": "Visning på {value}",
	"noMoreResidentials": "Inga fler bostäder",
	"welcomeName": "Välkommen, {name}",
	"youHaveBeenAddedToCompany": "Du är registrerad som användare hos {company}.",
	"activateAccount": "Aktivera konto",
	"pressButtonToActivateAccount": "Klicka på knappen för att välja ditt lösenord och aktivera kontot.",
	"orgNo": "Organisationsnummer",
	"howToGetLatLng": "För att placera ut projektet på kartan, rekommenderar vi dig att använda {0} för att få korrekta koordinater. Adressuppslag innehåller som oftast inga nya adresser och du är därför tvungen att ta fram detta manuellt. Dessutom kommer positionen att bli mer exakt.",
	"image": "Bild | Bilder",
	"flatfinderBuilder": "Upplägg",
	"leaflet-v1": {
		"drawToolbar": {
			"actions": {
				"title": "Avbryt skiss",
				"text": "Avbryt"
			},
			"finish": {
				"title": "Avsluta/Genomför ritning",
				"text": "Avsluta/Genomför"
			},
			"undo": {
				"title": "Radera den senast ritade punkten",
				"text": "Radera den senaste punkten"
			},
			"buttons": {
				"polygon": "Rita en markering"
			}
		},
		"drawHandlers": {
			"polygon": {
				"error": "Fel!",
				"tooltip": {
					"start": "Klicka för att rita en markering.",
					"cont": "Klicka för att fortsätta rita en markering.",
					"end": "Tryck på den första punkten för att stänga denna markering."
				}
			}
		},
		"editToolbar": {
			"actions": {
				"save": {
					"title": "Spara ändringar.",
					"text": "Spara"
				},
				"cancel": {
					"title": "Avbryt redigering, avvisa alla ändringar.",
					"text": "Avbryt"
				},
				"clearAll": {
					"title": "Radera alla markeringar.",
					"text": "Radera alla"
				}
			},
			"buttons": {
				"edit": "Redigera markeringar.",
				"editDisabled": "Inga markeringar att redigera.",
				"remove": "Radera markeringar.",
				"removeDisabled": "Inga markeringar att radera."
			}
		},
		"editHandlers": {
			"edit": {
				"tooltip": {
					"text": "Dra punkterna eller markera för att redigera.",
					"subtext": "Klicka på avbryt för att annullera ändringar."
				}
			},
			"remove": {
				"tooltip": {
					"text": "Tryck på en markering för att radera."
				}
			}
		}
	},
	"theme": "Tema",
	"selectTheme": "Välj tema",
	"show": "Visa",
	"activateMarketingButton": "Köp extra synbarhet",
	"couldNotLoadContent": "Kunde inte ladda innehåll",
	"couldNotLoadContentMessage": "Försök ladda sidan på nytt. Om problemet kvarstår, vänligen kontakta {0}.",
	"personalInformation": "Personliga upplysningar",
	"bidInformation": "Budinformation",
	"bidAuthentication": "Legitimation",
	"bidSigning": "Signering",
	"bidConfirmation": "Bud signerat",
	"purchaseSigning": "Signering",
	"purchaseConfirmation": "Köpekontrakt signerat",
	"socialSecurityNumber": "Personnummer",
	"bidApplicant": "Köpare",
	"bidHasCoApplicant": "Lägg till medköpare",
	"purchaseHasCoApplicant": "@:bidHasCoApplicant",
	"bidCoApplicant": "Medköpare",
	"amount": "Belopp",
	"bidExpiresAt": "Acceptfrist",
	"bidTakeoverAt": "Önskad överlåtelse",
	"bidCondition": "Eventuella förhållanden",
	"passwordEnforcementMessage": "Lösenordet måste innehålla minst 8 tecken, stora/små bokstäver samt minst 1 siffra",
	"bank": "Bank",
	"financeContact": "Kontaktperson",
	"financingPlan": "Finansieringsplan",
	"signingAuthority": "Fullmaktsinnehavare för signering",
	"bidContract": "Köpbekräftelse",
	"purchaseContract": "Köpekontrakt",
	"shopContract": "Tillvalsavtal",
	"shop-partialContract": "Tillvalsavtal ─ Delsignering",
	"bidSignedMessage": "Ditt bud har signerats och skickats. Du kommer inom kort att få en bekräftelse via e-post. Så snart ditt bud har behandlats, kommer du att få svar via e-post.",
	"purchaseSignedMessage": "Ditt köpekontrakt har signerats och skickats. Du kommer inom kort att få en bekräftelse via e-post. Så snart ditt köpekontrakt har behandlats, kommer du att få svar via e-post.",
	"gotoProject": "Gå till projektet",
	"parent": "Föräldrar",
	"goBack": "Gå tillbaka",
	"removeDemoContent": "Radera demo-innehåll",
	"unsavedChangesDialog": "Du har icke-sparade ändringar som går förlorade om du fortsätter. Är du säker på att du vill fortsätta?",
	"confirmation": "Bekräftelse",
	"yourDocumentsAreAttached": "Bifogat hittar du alla dokument",
	"accept": "Godkänn",
	"decline": "Avslå",
	"document": "Dokument | Dokument",
	"noDocuments": "Inga dokument",
	"projectsite": "Projektwebbsida",
	"signer": "Undertecknande | Undertecknande",
	"youDoNotHaveSignAuthority": "Endast fullmaktsinnehavare för signering kan godkänna/avslå detta dokument",
	"update": "Uppdaterar",
	"fieldsToSync": "Data som skall överskrivas",
	"selectAll": "Välj alla",
	"residentialTemplateSyncReferences": "Uppdatera relaterade bostäder",
	"askResidentialTemplateSyncReferences": "Vill du uppdatera anslutna bostäder?",
	"projectDomainSetup": "För att ändringen skall bli synlig på internet, måste du installera en DNS-pekare för din domän (se nedan). Kom ihåg att det kan ta lite tid innan ändringen är synbar på webben. Vid frågor, kontakta oss via vår kundchatt.",
	"lookingForAUserAdd": "Hittar du inte användaren du letar efter?",
	"select": "Välj",
	"syncReferencesWarning": "Var noggrann när du väljer vilka data som skall överskrivas. Alla valda fält kommer att överskrivas med det nya innehållet.",
	"themeDoesNotSupportColor": "Det valda temat stödjer inte möjligheten för temafärg.",
	"themeColor": "Temafärg",
	"youAreHere": "Du är här",
	"resetSelection": "Återställ val",
	"selection": "Val",
	"thisIsADemo": "Detta är en demo",
	"userIsGuest": "Gäst / Har inte tillgång till systemet",
	"guest": "Gäst",
	"inviteSent": "Inbjudan skickad",
	"demo": "Demo",
	"thisMessageHasBeenSentTo": "Denna information har skickats till",
	"tourSeoAnalyticsTagManager": "SEO / Analytics / Tag Manager",
	"tourDeveloperTools": "Användning av utvecklarverktyg",
	"tourBusinessSite": "Så här ändrar du företagssida",
	"tourRecommendedImageFormat": "Rekommenderade bildformat",
	"tourHowToAddUser": "Så här lägger till en användare",
	"tourHowToCreateProjects": "Så här skapar du projekt",
	"tourSingleResidentialSale": "Enmanshus-försäljning",
	"tourHowToAddLeads": "Så här lägger du till intressenter",
	"tourHowToCreateResidentials": "Så här skapar du bostäder",
	"addonPresentation": "Presentation",
	"addonBid": "Budgivning",
	"addonPurchase": "Köpekontrakt",
	"addonShop": "@:shop",
	"addonShopPurchase": "Tillvalsbutik ─ Signering",
	"tourAboutProjectDashboard": "Om projekt-dashboard",
	"tourHowToEditResidentials": "Så här redigerar du bostäder",
	"tourHowToDeleteResidentials": "Så här raderar du bostäder",
	"tourTitleOnScreen": "Hjälpguider (Interaktiv)",
	"tourTitleCourseVideos": "Utbildningsvideor",
	"tourTitleManuals": "Hjälpguider (Manual)",
	"flatfinderIsEntry": "Huvud-bostadsväljare (visa denna först på projektsidan)",
	"flatfinderCreateResidentialWarning": "När du lägger upp en bostadsväljare, så skall du ansluta bostäder. Det är därför viktigt att du först skapa bostäderna innan du lägger upp bostadsväljaren.",
	"flatfinderCreateResidentialWarningTitle": "Vi ser att du inte har skapat några bostäder",
	"flatfinderCreateResidentialWarningException": "Om du inte skall lägga upp bostäder nu, kan du bortse från detta meddelande och fortsätta.",
	"continue": "Fortsätt",
	"standard": "Standard",
	"richTextUpdateLink": "Uppdatera länk",
	"richTextAddLink": "Lägg till länk",
	"multiplicatorMonthly": "mån",
	"multiplicatorProject": "projekt",
	"contractStatusAccepted": "Godkänt",
	"contractStatusDeclined": "Inte godkänt",
	"contractStatusDraft": "Utkast",
	"contractStatusSigned": "Ett dokument väntar på godkännande",
	"contractStatusUnsigned": "Du har ett dokument för signering",
	"bidErrorStatus": "Bostaden är inte till salu",
	"bidErrorAddon": "Modul för budgivning har inte aktiverats för detta projekt",
	"purchaseErrorStatus": "Bostaden är inte såld",
	"purchaseErrorAddon": "Modul för köpekontraktet har inte aktiverats för detta projekt",
	"signed": "Signerat",
	"gotoSigning": "Gå till signering",
	"sign": "Signera",
	"username": "Användarnamn",
	"accountUrl": "Länk till konto",
	"password": "Lösenord",
	"otherBidAcceptedMessage": "Ett annat bud har accepterats",
	"bidConfirmationMessage": "Vi har mottagit ditt bud och du kommer att få svar från oss så snart som möjligt",
	"bidAcceptMessage": "Ditt bud har godkänts",
	"bidDeclinedMessage": "Ditt bud har inte godkänts",
	"purchaseConfirmationMessage": "Vi har mottagit ditt köpekontrakt och du kommer att få svar från oss så snart som möjligt",
	"purchaseAcceptMessage": "Ditt köpekontrakt har godkänts",
	"purchaseDeclinedMessage": "Ditt köpekontrakt har inte godkänts",
	"shopConfirmationMessage": "Vi har mottagit din signerade tillvalsbeställning. När motparten har signerat denna kommer du att få en bekräftelse. Bifogat hittar du en översikt över din beställning.",
	"shopAcceptMessage": "Ditt tillvalskontrakt har godkänts",
	"shopDeclinedMessage": "Ditt tillvalskontrakt har inte godkänts",
	"contractExpiredMessage": "Tidsfrist har löpt ut",
	"reason": "Motivering",
	"pressHereToCancelBid": "Vill du annullera köpet?",
	"youAreUsingBidAddon": "Du använder Kvass ─ Köpmodul",
	"pressHere": "Klicka här",
	"void": "Annullera",
	"reasonWillBeSentToApplicants": "Detta meddelande kommer att vidarebefordras till kund",
	"howToBuildContract": "För att dokumentet skall bli korrekt ifyllt, behöver du lägga upp en ifyllbar PDF-fil. Klicka på hjälp-knappen nedan, för att se hur du går tillväga.",
	"contractType": "Typ",
	"createDocument": "Skapa dokument",
	"upload": "Ladda upp",
	"bidUploadInfo": "Efter att du har laddat upp den signerade köpbekräftelsen kommer bostaden att markeras som såld",
	"onSoldStatusDialog": "För att markera denna bostad som såld, måste du ladda upp den signerade köpbekräftelsen.",
	"excludingVat": "exkl moms",
	"includingVat": "inkl moms",
	"priceOnRequest": "Pris efter förfrågan",
	"priceFrom": "Pris från",
	"pricePerMonth": "Pris per månad",
	"salePrice": "Försäljningspris",
	"priceMargin": "Tilläggsavgift",
	"purchasePrice": "Inköpspris",
	"readMore": "Läs mer",
	"upsaleRequestTemplate": "Jag vill ha hjälp med ”{0}”",
	"publishedFrom": "Publicerat från",
	"publishedTo": "Publicerat till",
	"customerService": "Kundtjänst",
	"residentialTemplateExplanation": "Genom att skapa bostadstyper slipper du att uppdatera samma underlag flera gånger. Du kan enkelt och snabbt uppdatera alla bostäder/ lägenheter som baserar sig på denna bostadstyp, samtidigt som du behåller möjligheten att ändra i bostäderna/ lägenheterna individuellt på projektnivå.",
	"unitTemplateExplanation": "Genom att skapa enhetstyp slipper du att uppdatera samma underlag flera gånger. Du kan enkelt och snabbt uppdatera alla enheter som baserar sig på denna typ, samtidigt som du behåller möjligheten att ändra enheterna individuellt på projektnivå.",
	"product": "Produkt | Produkter",
	"createProduct": "Skapa produkt",
	"allProducts": "Alla produkter",
	"category": "Kategori | Kategorier",
	"productCategorySample": "exempelvis Golv, Parkett, VVS",
	"more": "Mer",
	"residentialStatusBidLock": "Du har aktiverat ”Köpmodul” och kan inte ändra status till/från ”såld” manuellt. Gå in på bostad för att ändra status.",
	"noAnalyticsCollected": "Vi har inte samlat in tillräckligt med data ännu. Du kommer att se statistik här så snart någon besöker webbsidan.",
	"sendEmail": "Skicka e-post",
	"customThemeActiveMessage": "Du använder en skräddarsydd mall för detta projekt.",
	"validDnsSetupMessage": "Domänen har inte lagts upp på ett korrekt sätt ─ kontrollera instruktionerna nedan och försök igen.",
	"siteSettings": "Sida inställningar",
	"showOpenhouseOnRequestIfNone": "Visa ”@:openhouseOnRequest” om inga visningar har planerats",
	"showLeadUpsell": "Visa ”@:contactUpsell” på intressent-formuläret",
	"showShopOnResidentialPage": "Visa tillvalsbutik på bostad-sidorna",
	"showShop": "Visa tillvalsbutik",
	"typeHere": "Skriv här",
	"shopCategoryExamples": "exempelvis Kök, Bad",
	"shopSubcategoryExamples": "exempelvis Kakel, Inredning",
	"shopAddProduct": "Välj produkt",
	"shopAddCategory": "@:addCategory",
	"shopAddSubcategory": "Lägg till underkategori",
	"lookingForAProductAdd": "Hittar du inte produkten du letar efter?",
	"hideSold": "Dölj sålda",
	"assetManagerTitleBrochureDirect": "Ladda ned prospekt",
	"assetManagerTitleBrochureLead": "Ladda ned prospekt",
	"assetManagerTitleBrochureRequest": "Få prospektet hemskickat",
	"assetManagerActionBrochureLead": "Ladda ned prospekt",
	"assetManagerActionBrochureRequest": "Skicka",
	"assetManagerFeedbackBrochureRequest": "Vi skickar prospektet till dig inom kort tid.",
	"assetManagerCommentBrochureRequest": "Jag vill få prospektet skickat till mig.",
	"brochureDownloadStrategyLabel": "Välj hur prospektet kan laddas ned",
	"brochureDownloadStrategyDirect": "Ladda ned direkt",
	"brochureDownloadStrategyLead": "Anmäl intresse först",
	"brochureDownloadStrategyRequest": "Skicka förfrågan / Skicka manuellt",
	"selected": "Valt",
	"shopDefaultProduct": "Standardleverans",
	"shopProductUpgrade": "Uppgraderingsmöjligheter",
	"totalPrice": "Totalpris",
	"shopContactSellerForOtherInquiries": "Vid övriga önskemål, kontakta säljare",
	"shopNotAvailableSize": "Öppna denna sida på en större skärm för optimal användarupplevelse av tillvalsbutiken",
	"gotoItem": "Gå till {0}",
	"activate": "Aktivera",
	"whatDoYouWantToCopy": "Vad skall kopieras?",
	"helpTools": "Manualer / Guider",
	"needHelp": "Tränger du hjälp?",
	"title": "Titel",
	"nearbyarea": "Området",
	"shopSync": "Kopiera",
	"shopSyncAll": "Hela tillvalsbutiken",
	"badge-bid": "@:bid",
	"badge-comment": "@:comment",
	"badge-openhouse": "@:openhouse",
	"badge-shopcontract": "@:addonShop",
	"badge-lead": "@:lead",
	"badge-purchase": "@:addonPurchase",
	"badge-task": "Uppdrag",
	"badge-customcontract": "@:contract",
	"prepareContract": "Förtydligande av kontrakt",
	"prepareShop": "Förtydligande av tillval",
	"preparePurchaseContract": "Förtydligande av köpekontrakt",
	"buyer": "Köpare | Köpare",
	"sendToSigning": "Skicka till signering",
	"deadline": "Tidsfrist",
	"shopLink": "Länk till tillvalsbutik (presentation)",
	"shopLinkWithSelection": "Länk till tillvalsbutik (val & signering)",
	"shopSigning": "Signering",
	"shopConfirmation": "Bekräftelse",
	"shopIntro": "Inledning",
	"shopSummary": "Uppsummering",
	"shopSignedMessage": "Ditt tillvalskontrakt har signerats och skickats. Du kommer inom kort att få en bekräftelse via e-post. Så snart ditt tillvalskontrakt har behandlats kommer du att få svar via e-post.",
	"shopSigningNotReady": "Det är inte öppet för signering ännu och du kommer att få besked så snart du kan signera",
	"startShopContract": "Starta tillvalsprocess",
	"shopReadyTitle": "Din tillvalsbutik är klar",
	"shopReadyMessage": "Du kan nu se och välja vilka tillval du vill ha för din bostad. \nDet är inte öppet för signering ännu, men du kan redan nu göra dina första val. Så snart det är öppet för signering kommer du att få ett meddelande.",
	"shopWelcomeMessage": "Välkommen till din tillvalsbutik ─ här kan du se och välja vilka tillval du vill ha för din bostad.\n\nSå snart du har gjort dina val kan du går vidare till uppsummeringen där du kommer att få möjlighet att signera din beställning.",
	"shopUnsignedMessage": "Du kan nu välja och signera dina tillval",
	"gotoShop": "Gå till tillvalsbutiken",
	"shopContractStatusReady": "Klar (skickad till köpare)",
	"shopContractStatusDraft": "Utkast",
	"shopContractStatusUnsigned": "Väntar på signatur",
	"shopContractStatusSigned": "@:signed",
	"shopContractStatusExpired": "Tidsfristen har löpt ut",
	"shopContractNotSold": "Denna bostad är inte såld ännu. Så snart bostadens status ändras till såld kommer du att kunna initiera signeringsprocessen.",
	"shopContractAttachment": "Bilaga (signeras av köpare)",
	"shopContractStartRequirements": "Innan du kan starta tillvalsprocessen måste du lägga in köpare. \nKom ihåg att spara ändringarna. ",
	"shopContractRememberSignableFrom": "Kom ihåg att markera ”@:signableFrom” så att köparen kan signera tillvalsbeställningen.",
	"file": "Fil | Filer",
	"import": "Importera",
	"productImport": "Produkt import",
	"importedXItem": "Importerade {count} {item}",
	"importFailedResolveIssues": "Importen kunde inte genomföras. Nedan hittar du en översikt över vad som blev fel. Vänligen korrigera detta och försök på nytt.",
	"pressButtonBelowToGetStarted": "Klicka på knappen nedan för att komma igång",
	"signingDeadlineIs": "Tidsfristen för signering är",
	"shopLinkPresentation": "Presentation",
	"shopLinkSigning": "Val / Signering (för köpare)",
	"export": "Exporterar",
	"page": "Sida | Sidor",
	"tourGetStartedSingleShop": "Så här kommer du igång med: Enkel modul ─ Tillval",
	"tourHowToAddPartDeadlinesShop": "Så här anger du delfrister för tillvalssignering",
	"tourHowIfOrIfNotShopWork": "Så här fungerar det om/om inte processen för tillval",
	"tourGetStartedShop": "Så här kommer du igång med: Tillvalsbutik",
	"tourGetStartedShopContract": "Så här kommer du igång med: Digital tillvalssignering",
	"tourTaskManagement": "Så här fungerar uppdrag-styrning i kunduppföljningssystemet",
	"tourGetStartedPurchaseContract": "Så här kommer du igång med: Digitalt köpekontrakt",
	"tourGetStartedBidContract": "Så här kommer du igång med: Digital köpmodul",
	"tourGetStartedSingleBidContract": "Så här kommer du igång med: Enkel modul ─ Digital köpmodul",
	"tourHowToEditProjectResidentials": "Så här redigerar du projektet/bostäder",
	"tourHowToCreateFlatfinder": "Så här skapar/redigerar du bostadsväljare",
	"tourHowToCreateResidentialTemplate": "Så här skapar/redigerar du bostadstyper",
	"tourHowToBuildContract": "Så här skapar du köpbekräftelse",
	"tourHowDashboardWorks": "Så här fungerar dashboard",
	"tourHowToCreateProjectTeaser": "Så här skapar du en teaser (Kommande försäljning)",
	"tourRoles": "Så här administrerar du användare",
	"tourHowToPublish": "Så här publicerar du projektet under en domän",
	"tourTitleAdditionalServices": "Vill du ha assistans?",
	"tourHowToBuildPDF": "Så här skapar du en ifyllbar PDF-fil",
	"tourHowToHandleLead": "Så här hanterar du intressenter",
	"tourHowToHandleOpenhouse": "Så här hanterar du visningar",
	"howManyUnitsOnProject": "Hur många enheter/objekt skall säljas totalt?",
	"attachmentIsEncryptedWithSSN": "Notera att bilagan är krypterad ─ lösenordet är ditt personnummer. Dokumentet du har fått är en kopia och originalet finns hos säljaren.",
	"contractApprovalWarning": "Kom ihåg att kontrollera dokumentets innehåll för godkännande.",
	"addon:project": "Projekt",
	"addon:project:presentation": "Projekt-/Bostad-sida",
	"addon:project:bid": "Köpmodul",
	"addon:project:purchase": "Köpekontrakt",
	"addon:project:shop": "Tillval ─ Presentation",
	"addon:project:shop-purchase": "Tilvalg ─ Försäljning",
	"addon:custom-contract": "Dokumentsignering",
	"addon:api": "API-åtkomst",
	"addon:flatfinder": "Bostadsväljare",
	"addon": "Modul | Moduler",
	"fixedPrice": "Fastpris",
	"areaPrice": "Pris per m²",
	"customerPrice": "Pris till kund",
	"establishment": "Etablering",
	"ownershipType:leasehold": "Obligation",
	"ownershipType:freehold": "Ägare (självägande)",
	"ownershipType:other": "Annat",
	"ownershipType:partOwnership": "Andel",
	"ownershipType:collectiveOwnership": "Aktie",
	"plotOwnershipType:leasehold": "Obligation",
	"plotOwnershipType:freehold": "Ägare (självägande)",
	"plotOwnershipType:other": "Annat",
	"plotOwnershipType:partOwnership": "Andel",
	"plotOwnershipType:collectiveOwnership": "Aktie",
	"subpage": "Undersida | Undersidor",
	"orderItem": "Beställ {item}",
	"ShopPurchaseRequiresShop": "För att kunna ta i bruk ”Tillval ─ Försäljning”, behöver du även ”Tillval ─ Presentation”",
	"projectUnitLimitReached": "Du kan skapa ytterligare {0} enheter/objekt för detta projekt. För att lägga till fler enheter/objekt, behöver du uppgradera till en större licens. För uppgradering, vänligen kontakta oss via e-post: support@kvass.no eller via vår kundchatt.",
	"slug": "Slug",
	"content": "Innehåll",
	"setArea": "Ange area/yta",
	"rounding:none": "Ingen/Inget",
	"priceRounding": "Prisavrundning",
	"exampleShort": "exempelvis",
	"productOverrideMessage": "”{value}” är kopplad till en produktkatalog. Klicka på ”Redigera” för att ange ett eget värde.",
	"productOverrideReset": "Koppla samman ”{value}” med produktkatalog ─ ta bort eget värde.",
	"priceMissingArea": "Area/Yta saknas",
	"total": "Totalt",
	"instagramProfile": "Instagram-profil",
	"numberOfPosts": "Antal inlägg",
	"followUsOn": "Följ oss på {value}",
	"customFieldsResidentialTemplatesLabel": "Presentation av bostadstyper",
	"signableFrom": "Kan signeras från",
	"setPartialDeadlines": "Ange delfrister",
	"removePartialDeadlines": "Ta bort delfrister",
	"partialDeadline": "Delfrist | Delfrister",
	"madeBy": "En tjänst från",
	"gotoSummary": "Gå till uppsummering",
	"selectCategoriesToSign": "Välj kategorier som skall signeras",
	"shopBuyerStatusDraft": "Inte öppet för signering ännu",
	"shopBuyerStatusUnsigned": "Klar för signering",
	"wholeShop": "Hela tillvalsbutiken",
	"company": "Företag",
	"companyName": "Företagsnamn",
	"businessSite": "Företagssida",
	"private": "Privat",
	"footer": "Sidfotstext",
	"creatingContracts": "Skapar dokument för signering, vänligen vänta ett ögonblick.",
	"pressButtonBelowToGoToShop": "Klicka på knappen för att gå till din tillvalsbutik",
	"youHaveNewShopInfo": "Du har mottagit uppdaterad information för din tillvalsbutik",
	"youHaveAnUpcomingDeadline": "Du har en tidsfrist som snart löper ut",
	"hiName": "Hej, {name}",
	"shopPresentationSidebarDescriptionTitle": "Lägg upp din bostad",
	"shopPresentationSidebarDescriptionContent": "Här kan du se vilka uppgraderingar som finns tillgängliga för denna bostad.",
	"signingLoadingMessage": "Vi färdigställer dina dokument ─ vänligen vänta ett ögonblick (detta tar cirka 15─30 sek.)",
	"tag": "Märkningsetikett/Tagg | Märkningetiketter/Taggar",
	"noTags": "Inga märkningslappar",
	"youHaveANewMessageFrom": "Du har fått ett nytt meddelande från {from}",
	"nameSays": "{name} säger",
	"gotoDialog": "Gå till dialogen",
	"youCanAswerThisEmail": "Du kan svara på detta e-postmeddelande ─ ditt svar kommer att vidarebefordras",
	"send": "Skicka",
	"note": "Anteckning",
	"thisMessageWasSentWith": "Detta meddelande har skickats med",
	"addCategory": "Lägg till kategori",
	"availableIf": "Tillgänglig om",
	"availableIfNot": "Inte tillgänglig om",
	"xActiveConditions": "Inga aktiva regler | {count} aktiv regel | {count} aktiva regler",
	"setConditions": "Ange regler",
	"displayName": "Visningsnamn",
	"useDisplayName": "Använd ett annat visningsnamn",
	"statisticsVisitors": "Besökstal",
	"statisticsSalesResidentials": "Försäljningstal",
	"statisticsSalesShop": "Försäljningstal (Tillval)",
	"externalUserAlert": "Du försöker lägga till en användare som inte ingår i din organisation ({domain}). \nVederbörande kommer att få åtkomst till projekten och tillhörande data i produktlösningen.\n\nVänligen bekräfta att du vill lägga till {email}.",
	"sendMessageToSeller": "Skicka meddelande till säljare",
	"sendMessageToSellerButtonLabel": "Funderar du över något? – Kontakta oss här",
	"sent": "Skickat",
	"member": "Medlem | Medlemmar",
	"editTask": "Redigera uppdrag",
	"createTask": "Skapa uppdrag",
	"undone": "Inte startat",
	"noDescription": "Ingen beskrivning",
	"noDeadline": "Ingen frist",
	"youHaveANewTask": "Du har fått ett nytt uppdrag",
	"gotoTask": "Gå till uppdraget",
	"gotoDashboard": "Gå till dashboard",
	"youHaveXTaskDueIn": "Du har {count} uppdrag med frist som löper ut {dueby} | Du har {count} uppdrag med frist som löper ut {dueby}",
	"projectAdditionalFieldsDescriptionFooter": "Lägg till sidfotstext, exempelvis: Om oss/Åtkomst",
	"projectAdditionalFieldsDescriptionResidentailTemplate": "Välj vilka av dina bostadstyper du vill presentera på projektsidan.",
	"projectAdditionalFieldsDescriptionArea": "Lägg in texter och bilder för att presentera området eller liknande.",
	"projectAdditionalFieldsDescriptionSubpage": "Skapa undersidor där du kan presentera bilder och text. Du kan länka till undersidor genom att lägga till länkar i textfälten.",
	"clone": "Duplicera",
	"type": "Typ | Typer",
	"filter": "Filtrera",
	"noShopDefaultProduct": "Ingen standardleverans",
	"customer": "Kund | Kunder",
	"purchaseManuallyConfirmed": "Uppladdat köpekontrakt är signerat",
	"cannotAddMoreUsers": "Du kan inte lägga till flera användare. Vänligen kontakta kundtjänst om du vill lägga till fler.",
	"videoPreviewMessage": "Lägg till {type} länk för förhandsvisning av video",
	"videoLink": "{type} länk",
	"profile": "Profil | Profiler",
	"redirectToExternalSite": "Vidarekoppla till extern sida",
	"residentialRedirectUrlExample": "exempelvis https://...",
	"optional": "valfritt",
	"residentialRedirectUrlExplanation": "Använd detta fält om du vill skicka vidare besökare till en annan webbplats.",
	"createdAt": "Skapat den",
	"overview": "Översikt",
	"mediaTypeComboMessage": "Eller klicka på knappen för att välja bland andra mediatyper.",
	"mediaTypeDefaultMessage": "Välj media-typ via knappen",
	"userIsDeveloper": "Visa utvecklarverktyg",
	"noFloorplansSetup": "Inga planskisser har skapats",
	"mark": "Markera",
	"createResidentialTemplateBeforeShop": "För att kunna skapa tillvalsbutiken, måste du först ange/skapa bostadstyp",
	"noShopsetsSetup": "Inget produktpaket har skapats",
	"shopSet": "Produktpaket | Produktpaket",
	"productsThatArePartOfSet": "Produkter som finns i produktpaketet",
	"manualSelectAndSkip": "Välj själv (Hoppa över)",
	"event": "Händelse | Händelser",
	"url": "Url",
	"enabled": "Aktiverat",
	"webhook": "Webhook | Webhooks",
	"createWebhook": "Skapa webhook",
	"setting": "Inställning | Inställningar",
	"webhookSecret": "Secret",
	"attempt": "Försök",
	"of": "av",
	"log": "Logg | Logg",
	"noWebhooks": "Inga webhooks",
	"webhookIsSystem": "Systemintegrering",
	"noLogs": "Inga loggar",
	"token": "Token | Token",
	"createToken": "Skapa token",
	"noTokens": "Inga token",
	"acl": "Tillgångskontroll",
	"tokenSecretMessage": "Vänligen kopiera denna nyckel och spara den på ett säkert ställe. Av säkerhetsskäl visar vi den endast en gång.",
	"shopContractNoticeSellerApproachingSubject": "Köpares signeringsfrist löper ut",
	"shopContractNoticeSellerOverdueSubject": "Köpares signeringsfrist har löpt ut",
	"shopContractNoticeSellerExpires": "Köpare har inte genomfört signering av tillvalskontrakt.",
	"unit": "Enhet | Enheter",
	"noUnits": "Inga enheter",
	"noMoreUnits": "Inga fler enheter",
	"createUnit": "Skapa enhet",
	"unitTemplate": "Enhetstyp | Enhetstyper",
	"residentialSaleWithBroker": "Försäljning med mäklare ",
	"residentialSaleSelf": "Digitalt kontraktsupprättande",
	"residentialSaleWithBrokerBidHelperText": "Här kan du lägga till en valfri länk via vilken du kan sälja objektet. Länken kommer att finnas \nunder ”köp”-knappen på bostad-sidan. ",
	"bidContractMissingShowBidUrl": "Köpbekräftelse saknas. Ladda upp köpbekräftelse för projektet för att se budgivningslänk. ",
	"residentialSaleBidHelperText": "Med denna länk kan du sälja objektet digitalt. \nLänken är tillgänglig under ”köp”-knappen på bostad-sidan, men länken kan även kopieras härifrån. \nKöp som genomförts via denna länk kommer att figurera i säljarens dashboard. ",
	"residentialSaleBidActiveHelperText": "Köpmodulen har aktiverats. Om du vill ta i bruk en annan försäljningsmetod kan du beställa systemintegration. \nKontakta oss via knappen nedan för mer information och beställning. ",
	"pressButtonBelowToGoToShopNoticeSeller": "Klicka på knappen för mer information om tillvalsbutiken",
	"copy": "Kopiera",
	"copied": "Kopierat",
	"paywallContentFieldUsedByFollowingModules ": "Dessa fält är inte relevanta för aktiverade moduler",
	"paywallContentPressButtonToReadMore": "Klicka på knappen för att läsa mer eller aktivera modulen | Klicka på en av knapparna för att läsa mer eller aktivera modulen",
	"deliveryLogClickLabel": "{resource} levererat ─ öppnat",
	"deliveryLogOpenLabel": "{resource} levererat ─ sett",
	"deliveryLogFailedLabel": "{resource} kunde inte levereras",
	"deliveryLogDeliveredLabel": "{resource} levererat ─ inte öppnat",
	"deliveryLogUnknownLabel": "{resource} ─ väntar på leverans",
	"invite": "Inbjudan",
	"info": "Info",
	"oneOrMoreDeadlinesExpired": "En eller flera frister har löpt ut",
	"projectShopsOpenByBuyer": "Öppnat av köpare",
	"contractStatusNotAccepted": "Väntar på godkännande",
	"noMoreShops": "Inga fler tillvalsbutiker",
	"projectShopsCreateResidentialsWarning": "Skapa bostäder innan du lägger upp tillvalsbutiker.",
	"projectShopsEditShop": "Inte skapat",
	"projectShopsCreateResidentials": "Skapa bostäder",
	"projectShopsNotSold": "Inte sålt",
	"projectSaleBrokerGuide1": "Under “försäljning” på enheter kan du lägga till länkar till köpprocessen från mäklarlösningar",
	"projectSaleBrokerGuide2": "T.ex. tryggbudgivning.",
	"projectSaleBrokerGuide3": "Om så önskas kan även integration mot mäklarlösningar beställas.",
	"residentialsFlatfinderGuideInfo": "Efter att du har lagt till enheter kan du om så önskas skapa bostadsväljare. Detta gör du genom att gå till projektdashboarden och trycka på “skapa” och sedan “bostadsväljare”.",
	"shop": "Tillvalsbutik | Tillvalsbutiker",
	"devtools": "Utvecklarverktyg",
	"embed": "Inbäddning",
	"imageRecommendation": "Rekommenderat bildformat: {width} {symbol} {height}",
	"height": "höjd",
	"width": "bredd",
	"imageFlatfinderRecommendation": "Välj fritt – visningen anpassar sig därefter",
	"consentRequiredTitle": "Nödvändiga cookies",
	"consentRequiredDescription": "Nödvändiga cookies säkerställer grundläggande funktioner, såsom navigation till sidor och åtkomst till säkra områden på webbplatsen. Webbplatsen kan inte fungera optimalt utan dessa cookies.",
	"consentStatisticsTitle": "Statistik",
	"consentStatisticsDescription": "Analytiska cookies hjälper oss att förbättra vår webbplats genom att samla in och rapportera statistisk information om användningen. Informationen är anonymiserad.",
	"consentMarketingTitle": "Marknadsföring",
	"consentMarketingDescription": "Marknadsföringscookies används för att visa innehåll från sociala medier och annonser som är mer relevanta och engagerande.",
	"consentDialogTitle": "Vill du acceptera cookies?",
	"consentDialogDescription": "Vi använder cookies för att ge dig en så bra användarupplevelse som möjligt. Dessa cookies används för att analysera vår trafik och förbättra webbplatserna, samt för att personanpassa innehåll och annonser.\n  För mer information, se vår <a href=\"/api/legal/privacy/tenant\">sekretesspolicy</a>.",
	"consentDialogLabelAccept": "Ja, acceptera alla",
	"consentDialogLabelAcceptAll": "Acceptera alla",
	"consentDialogLabelConfirm": "Bekräfta",
	"consentDialogLabelConfigure": "Nej, ändra inställningar",
	"editConsents": "Ändra sekretessinställningar",
	"noAttachments": "Inga bilagor",
	"tourVideoUpload": "Hur man lägger till en video",
	"language": "Språk",
	"textOverwriteWarning": "Följande text visas om {value} saknas:",
	"cookieVideoBlockMessage": "Vi behöver ditt samtycke för att spela upp den här videon",
	"sizes": "Storlekar",
	"productLink": "Produktlänk",
	"showDefaultSelection": "Visa standardval",
	"noUpgradesSelected": "Inga uppgraderingar valda",
	"setValue": "Ställ in {value} ...",
	"internalName": "Internt namn",
	"useInternalName": "Använd eget internt namn",
	"shopWelcomeText": "Välkomsttext",
	"scopedProduct": "Inte från katalog",
	"contactImport": "Kontaktimport",
	"importTemplateDescription": "för importering",
	"socialMedia": "Sociala medier",
	"downloadTemplate": "Ladda ner mall",
	"projectCreationVideoMessage": "Kolla in hur du kan använda Kvass på {type} – se film ovan!",
	"singleResidential": "Enbostadshus | Enbostadshus",
	"myPage": "Min sida",
	"contractStatusNotAcceptedBySeller": "Väntar på godkännande av säljare",
	"contractStatusAcceptedBySeller": "Godkänd av säljare",
	"followSoMeTitle": "Följ oss på sociala medier",
	"projectAdditionalFieldsSoMeDescriptionArea": "Lägg till länk till dina sociala medier och visa upp detta på webbplatsen.",
	"projectAdditionalFieldsSeoDescriptionArea": "Skriv över SEO-anpassningar (titel och beskrivning) Läs mer",
	"projectAdditionalFieldsFacebookAdsDescriptionArea": "Lägg till fält för skapande av Facebook-annonsering",
	"SEO": "SEO",
	"facebookAds": "Facebook-annonsering",
	"back": "Tillbaka",
	"newFolder": "Ny mapp",
	"dragFolderOrDrop": "Dra mappar hit eller <b>klicka</b> för att ladda upp",
	"managementOperationMaintenance": "FDU-dokumentation",
	"residentialAccount": "Min sida",
	"clickToFilter": "Tryck för att filtrera",
	"imagesOnlyAsIllustration": "Bilder är avsedda som illustrationer",
	"foundXResults": "Hittade {0} resultat",
	"active": "Aktiv",
	"inactive": "Inaktiv",
	"contract": "Kontrakt | Kontrakt",
	"buyConfirmation": "Köp signerat",
	"residentialAccountLogin": "Logga in på min sida",
	"loginPressButton": "Klicka på knappen nedan för att logga in",
	"loginVisitLink": "Eller klistra in den här länken i webbläsaren",
	"loginLinkExpires": "Den här länken upphör att gälla om {expires} minuter",
	"loginGetNewLink": "få skickat en ny länk",
	"residentialAccountYourUnits": "Dina enheter",
	"residentialAccountLoginTooSeeUnits": "Logga in för att se dina enheter",
	"loginEnterValue": "Ange {value} och tryck på “Avsluta/Genomför”.",
	"loginSuccessMessage": "Du bör nu ha fått ett e-postmeddelande med mer information",
	"sortOn": "Sortera efter",
	"highLow": "hög-låg",
	"lowHigh": "låg-hög",
	"syncUser": "Hämta användardata",
	"lowHighLetter": "Ö - A",
	"highLowLetter": "A - Ö",
	"addFilter": "Lägg till filter",
	"resetFilter": "Återställ filter",
	"validTo": "Giltig till",
	"registrationComplete": "Du har slutfört registreringen.",
	"clickToLogin": "Tryck på knappen nedan för att logga in.",
	"welcomeToKvass": "Välkommen till Kvass",
	"enterPasswordAndClickComplete": "Ange lösenord och tryck på “Avsluta/Genomför”.",
	"tokenExpiredRequestNew": "Din inbjudan har upphört att gälla, be om en ny. ",
	"author": "Författare",
	"post": "Inlägg | Inlägg",
	"createPost": "Skapa inlägg",
	"shopShort": "Tillval",
	"developer": "Utvecklare",
	"access": "Tillgång | Tillgångar",
	"supplier": "Leverantör | Leverantörer",
	"technicalDescription": "Teknisk beskrivning",
	"addTechnicalDescription": "Lägg till teknisk beskrivning",
	"switchTenant": "Byt konto",
	"leadMessageSent": "Tack för ditt intresse. Du kommer snart att kontaktas av en kundtjänstmedarbetare.",
	"externalUserAlertAdmin": "Åtkomst till projekten och tillhörande data i produktlösningen ",
	"externalUserAlertNoRights": "Ingen åtkomst till data i produktlösningen",
	"externalUserAlertRoles": "Roll som: {roles} ",
	"externalUserAlertAccess": "Åtkomst till: {access} ",
	"shopPresentationPreviewAlert": "Tillvalsbutiken saknar data. \nFörhandsvisning kommer vara tillgänglig efter att tillvalsbutiken har skapats.",
	"addon:project:complaint": "Reklamation",
	"archived": "Arkiverad",
	"showArchived": "Visa arkiverade",
	"archive": "Arkivera",
	"integrationCompliance": "Det bekräftas härmed att Kvass ombes att överföra personuppgifter genom vår integrering och att jag/vi är självständig registeransvarig för överföringen och den vidare behandlingen av personuppgifterna.",
	"documentSigned": "Dokument signerat",
	"documentSignedConfirmationMessage": "Dokumentet har signerats och skickats. Du kommer inom kort att få en bekräftelse via e-post. Så snart ditt dokument har behandlats, kommer du att få svar via e-post.",
	"integrationNotificationEmailBody": "Vi vill informera om att en ny {type} ha lagts till på kontot: {account}.",
	"integrationNotificationEmailBodyReminder": "Kom också ihåg att säkerställa att nödvändiga personuppgiftsbiträdesavtal har ingåtts med alla underleverantörer.",
	"integrationNotificationEmailSubject": "En ny {type} med namn “{name}” har lagts till",
	"noContracts": "Inga kontrakt",
	"awaitingSignature": "Väntar på signatur",
	"residentialAddressControl": "Kontrollera adress",
	"residentialAccountNotSold": "Denna enhet är inte såld ännu. Så fort enhetens status ändras till såld, kommer inställningar att vara tillgängliga.",
	"createContract": "Skapa kontrakt",
	"lastUpdated": "Senast uppdaterad",
	"complaint": "Reklamation | Reklamationer",
	"lookingForAContactAdd": "Hittar du inte kontakten du letar efter?",
	"orderConfirmationCondition": "Alla produkter som aktiveras har en bindningstid på {lockInPeriod}. Produkter förnyas automatisk löpande med {noticePeriod} om den inte skriftligen sägs upp av en av parterna innan innevarande period utlöper.",
	"orderConfirmationConditionShort": "{lockInPeriod} bindningstid / förnyas automatiskt efter {noticePeriod} ",
	"orderConfirmation": "Orderbekräftelse",
	"unitLimitMessage": "Du kan skapa upp till {limit} enheter.",
	"taskStatusUndone": "Öppen",
	"taskStatusDone": "Avslutat/Genomfört",
	"taskStatusRejected": "Inte godkänt",
	"taskStatusRequest": "Förfrågan",
	"subjectArea": "Yrkesområdet",
	"showCompleted": "Visa avslutade/genomförda",
	"complaintRequest": "Begäran | Begäranden",
	"complaintTermsOfRequest": "Vad gäller det?",
	"complaintAddSelection": "Lägg till markering",
	"complaintRegister": "Skicka in reklamation",
	"companyOrder": "{company} har beställt tjänsten",
	"activatedBy": "Tjänsten aktiverades av {user}",
	"orderConfirmationAttached": "Bifogat hittar du en orderbekräftelse.",
	"install": "Installera",
	"uninstall": "Avinstallera",
	"installed": "Installerad",
	"residentialAccountPublishAlert": "Vid publicering kommer ägare motta meddelande om inloggning på Min sida.",
	"reset": "Återställ",
	"showLess": "Visa mindre",
	"residentialAccountAccess": "Du har fått åtkomst till Min sida",
	"taskChangeStatus": "{type} '{title}' är {status}",
	"taskChangeStatusSubject": "{type} är {status}",
	"residentialAccountSettingsShowShop": "Tillgängliggör tillvalsbutiken på Min sida",
	"residentialAccountSettingsShowShopSublabel": "Visa sammanfattning, tidsfrister och annat relevant innehåll",
	"residentialAccountSettingsShowShopDocuments": "Visa dokumentation på beställda tillval på Min sida",
	"residentialAccountSettingsShowShopDocumentsSublabel": "Dokumentation autogenereras och visas under FDU-innehåll",
	"residentialAccountSettingsShowResidentialDocuments": "Visa enhetens dokument på Min sida",
	"residentialAccountSettingsShowResidentialDocumentsSublabel": "Kontakt, planskisser, bilagor och prospekt",
	"redirectUrl": "Adress för vidarebefordring",
	"contactSeller": "Kontakta säljare",
	"importFromCatalog": "Hämta från katalog",
	"reject": "Avslå",
	"rejected": "Avvisad",
	"addon:project:flatfinder": "Bostadsväljare",
	"blockComplaints": "Blockerar mottagande av reklamationer för den här enheten",
	"unblockComplaints": "Upphäv blockering av reklamationer för den här enheten",
	"complaintsBlocked": "Mottagande av reklamationer för den här enheten är blockerat.",
	"taskStatusChangeToUndone": "under behandling",
	"taskStatusChangeToDone": "behandlat",
	"taskStatusChangeToRejected": "inte godkänt",
	"attachmentsSigningUploadWarning": "Bilagor måste läsas igenom innan signering kan genomföras. \nOBS: Bilagor kommer inte inkluderas i huvudkontrakt som signeras.",
	"addonIsIncludedIn": "{addon} är inkluderat i {in}",
	"tourHowToEmbedFlatfinder": "Bädda in bostadsväljare på externa sidor",
	"helpers:roleManager": "Kan redigera projekt och enheter.",
	"helpers:roleSigningAuthority": "Kan godkänna eller avslå köp.",
	"helpers:roleSalesman": "Får läsåtkomst och visas som kontaktperson.",
	"helpers:roleShopManager": "Kan redigera tillvalsbutiker.",
	"helpers:roleShopSigningAuthority": "Kan godkänna eller avslå tillvalskontrakt.",
	"helpers:roleShopSalesman": "Får läsåtkomst och visas som kontaktperson.",
	"salesmanShopVisibility": "Kundtjänstmedarbetarnas kontaktinformation för tillval kommer att visas under signeringsprocessen",
	"productBundleContains": "Inkluderar följande produkter",
	"productBundle": "Produktpaket",
	"rent": "Uthyrning",
	"saleType": "Försäljningstyp",
	"actionLabel:export": "Exporterar",
	"actionLabel:integration": "Integrationer",
	"action": "Åtgärd | Åtgärder",
	"helpers:productBundle": "Ett produktpaket är en kombination av flera enskilda produkter. Du kan välja vilja produkter som ingår, och slutkunden kan välja produktpaketet och se information om varje enskild produkt.",
	"office": "Kontor",
	"integrationDpaRecommendation": "Förslag till personuppgiftsbiträdesavtal",
	"ordinaryProductPrice": "Ordinarie produktpris",
	"helpers:shopPricingMethodTitle:add": "Standard",
	"helpers:shopPricingMethodDescription:add": "Här är standardprodukten alltid inkluderad i priset. Pris på uppgraderingar utgår från ordinarie produktpris.",
	"helpers:shopPricingMethodTitle:diff": "Skillnad",
	"helpers:shopPricingMethodDescription:diff": "Här beräknas mellanskillnaden mellan standardprodukten och uppgraderingen.",
	"selectMethod": "Välj metod",
	"priceCalculation": "Prisberäkning",
	"unitNumber": "Enhetsnummer",
	"productUpdateAffectedProducts": "Ändringarna påverkar {products} produktval i {shops} tillvalsbutiker",
	"upcomingLabel": "Kommer snart",
	"hide": "Dölj",
	"residentialAdditionalFieldsLabel": "Övriga fält som kan läggas till:",
	"link": "Länk | Länkar",
	"loggingIn": "Loggar in",
	"setDueDates": "Ange tidsfrister",
	"complaintDescription": "Beskriv reklamationen",
	"helpers:managementOperationMaintenance": "Ladda upp FDU-dokument här. Samtliga dokument kommer visas på Min sida. \nDessutom har du möjlighet att visa dokumentation på tillval, planskisser, kontrakt och bilagor kopplade till enheten (kan aktiveras under inställningar).",
	"helpers:roleComplaintManager": "Mottar alla inkommande reklamationsärenden",
	"residentialAccountLoginCondition": "E-postadressen måste vara registrerad i systemet",
	"noComplaints": "Inga reklamationer",
	"complaintDisclaimerConsent": "Jag bekräftar att vid utredning av obefogade reklamationsbegäranden, kommer kunden kunna belastas med våra faktiska kostnader.",
	"complaintDisclaimerEdit": "Redigera ansvarsfriskrivning",
	"fdvControlLabel": "FDU är uppladdad och kontrollerad",
	"fdvControlSublabel": "Kryssa för när FDU är komplett och kontrollerad",
	"shopEditDueAtUnitNotSold": "Bostäderna är inte sålda ännu. Så snart bostadens status ändras till såld kommer du att kunna ange tidsfrister.",
	"confirmed": "Bekräftat",
	"control": "Kontroll",
	"noMembers": "Inga medlemmar",
	"complaintRoleMissing": "Roll för hantering av reklamationer saknas för projektet. ",
	"folder": "Mapp | Mappar",
	"helpers:complaintsList": "Via Min sida kan köpare skicka in reklamationer och samtliga reklamationsärenden för denna enhet visas här.",
	"completeTask": "Avsluta/Genomför uppgift",
	"changeStatus": "Ändra status",
	"confirmRequest": "Bekräfta förfrågan",
	"rejectRequest": "Avslå förfrågan",
	"setTaskToUndone": "Återställ till “Öppen”",
	"cannotEditMultipleResidentialFieldsSameSaleType": "Enheterna har olika försäljningstyp och kan därför inte redigeras tillsammans",
	"noContacts": "Inga kontakter",
	"filterEmptyState": "Ändra filtren och försök igen",
	"invoice": "Faktura",
	"receiver": "Mottagare | Mottagare",
	"addon:project:presentation:sublabel": "Skapa snabbt en webbplats med bostadsväljare.",
	"addon:project:bid:sublabel": "Skicka ut och signera köpkontrakten digitalt.",
	"addon:project:purchase:sublabel": "Sälj enkelt och digitalt via projekt-/bostadssidan.",
	"addon:project:shop:sublabel": "Presentera tillval digitalt via Projekt-/bostadssidan eller med en egen länk.",
	"addon:project:shop-purchase:sublabel": "Sälj tillval digitalt via Projekt-/bostadssidan, Min sida eller med en egen länk.",
	"addon:project:residential-account:sublabel": "Ge dina kunder åtkomst till Min sida",
	"addon:project:residential-account": "Min sida (inkl. vid aktiv projektlicens)",
	"billing": "Fakturamottagare",
	"featuredResidentialPropertyHelper": "Lyft fram detta nyckelvärde på projektsidan",
	"viewProject": "Se projekt",
	"unitSelectorIsEntry": "Huvudenhetsväljare",
	"autocompleteAddressAlert": "Har du ingen adress ännu? Flytta markören på kartan för att ange position.",
	"externalFileDisclaimer": "Innehållet i filerna är inte kontrollerat. Du är själv ansvarig för nedladdning av externa filer.",
	"getMarkerPosition": "Hämta position från karta",
	"createResource": "Skapa {resource}",
	"taskCommentsReceiverInfo": "Meddelanden skickas till medlemmar och den som har skapat uppgiften",
	"createdBy": "Skapad av",
	"openhouseEmptyState": "Kom igång genom att skapa en visning",
	"documentsEmptyState": "Det finns inga dokument knutna till den här enheten",
	"startShops": "Starta fler tillvalsbutiker",
	"noUnitTemplates": "Inga enhetstyper",
	"unitTemplatesEmptyState": "Kom igång genom att skapa en enhetstyp",
	"noApplicantShopContractTooltip": "En eller flera av tillvalsbutikerna är inte redo att startas än och kommer därför att ignoreras.",
	"noUsers": "Ingen användare",
	"usersEmptyState": "Kom igång genom att skapa en användare",
	"cannotCreateMoreX": "Kan inte skapa flera {x}",
	"cannotDelete": "Kan inte radera",
	"noX": "Ingen/Inget {x}",
	"thisFieldIsFetchedFromX": "Detta fält hämtas från {x}",
	"addSecondaryColor": "Lägg till @:secondaryColor",
	"primaryColor": "Primärfärg",
	"secondaryColor": "Sekundärfärg",
	"unitMustBeSoldToSetOwner": "Enheten måste vara såld/uthyrd innan du kan välja en ägare för enheten",
	"owner": "Ägare | Ägare",
	"tableFooterTip": "Tips: Tryck på och håll nere {0} för att markera flera samtidigt.",
	"sendMessage": "Skicka meddelande",
	"mediaDescriptionPlaceholder": "Lägg in bildtext",
	"taskTypeDefault": "@:task",
	"taskTypeComplaint": "@:complaint",
	"downloadX": "Ladda ner {x}",
	"noUnsavedChanges": "Inga osparade ändringar",
	"installMore": "Installera fler",
	"propertyType:four-person-home": "Tvåfamiljshus",
	"addCompanyInfo": "Lägg till företagsinformation",
	"shopTotalLabel": "Totalpris för tillval",
	"addDesiredAmount": "Lägg till önskat belopp nedan",
	"creationDate": "Datum för skapande",
	"shop-partialConfirmationMessage": "Vi har mottagit din signerade tillvalsbeställning. När motparten har signerat denna kommer du att få en bekräftelse. Bifogat hittar du en översikt över din beställning.",
	"shop-partialAcceptMessage": "Ditt tillvalskontrakt har godkänts",
	"shop-partialDeclinedMessage": "Ditt tillvalskontrakt har inte godkänts",
	"customConfirmationMessage": "Vi har mottagit ditt kontrakt och du kommer att få svar från oss så snart som möjligt",
	"customAcceptMessage": "Ditt kontrakt har godkänts",
	"customDeclinedMessage": "Ditt kontrakt har inte godkänts",
	"noteVisibilityMessage": "Endast synlig internt",
	"flatfinderTypeLeisure": "Stugväljare | Stugväljare",
	"importContact": "Importera kontakt",
	"externalProductCategory": "Produkter från externa leverantörer",
	"shopExternalCategoryLabel": "Övriga produkter",
	"shopExternalCategoryDescription": "Produkter som lagts till av externa leverantörer/samarbetspartners",
	"shopExternalProductActionLabel": "Ge åtkomst till extern leverantör",
	"shopExternalProductActionAlert": "Kopiera länken nedan och dela med dina leverantörer/samarbetspartners. Tryck på bekräfta för att gå till sidan.",
	"shopExternalProductInfo": "I formuläret nedan kan du ladda upp en ny produkt i produktbutiken till {shop}.",
	"shopExternalProductAddTitle": "Lägg till ny produkt",
	"productName": "Produktnamn",
	"shopExternalDescriptiveCategoryLabel": "Var görs ändringen?",
	"shopExternalSupplierSublabel": "Vem som laddar upp produkten",
	"canEdit": "Kan redigera",
	"usersWithAccess": "Användare med åtkomst",
	"addon:page": "Sida",
	"createPage": "Skapa sida",
	"jointDebt": "Gemensam skuld",
	"hasJointDebt": "Bostaden har gemensam skuld",
	"premiumDesignTemplateTooltip": "Premium designmall",
	"about": "Om {x}",
	"contactBroker": "Kontakta mäklare",
	"energyLabelColor:darkGreen": "",
	"energyLabelColor:lightGreen": "",
	"energyLabelColor:yellow": "",
	"energyLabelColor:orange": "",
	"energyLabelColor:red": "",
	"lift": "",
	"roofTerrace": "",
	"garage": "",
	"janitorservice": "",
	"balcony": "",
	"lookAt": "",
	"rentPageMadeBy": "",
	"submit": "",
	"importStarted": "",
	"available": "",
	"all": "",
	"commercial": "",
	"environmentSustainability": "",
	"clockAt": "på",
	"admin": "",
	"commercialProperty": "",
	"projectDevelopment": "",
	"seeMorePictures": "Se fler bilder",
	"customPrivacySublabel": "",
	"customPrivacyAlert": "",
	"privacyPolicyTitle": "",
	"projectConsentDialogDescription": "Vi använder cookies för att ge dig en så bra användarupplevelse som möjligt. \nDessa cookies används för att analysera vår trafik och förbättra webbplatserna, samt för att personanpassa innehåll och annonser.\nFör mer information, se vår {link}.",
	"pageConsentDialogDescription": "Vi använder cookies för att ge dig en så bra användarupplevelse som möjligt. \nDessa cookies används för att analysera vår trafik och förbättra webbplatserna, samt för att personanpassa innehåll och annonser.\nFör mer information, se vår {link}."
}